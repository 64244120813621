* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar { width: 0px }

.APP-color { color: rgb(2, 2, 77) !important;}
/* .APP-background { background-color: rgba(245,247,250,255) !important;} */
.APP-background { background-color: #e9f0f7 !important;}
.APP-border { color: rgb(2, 2, 77) !important;}
.APP-background_2 { background-color: rgba(102, 153, 209, 255) !important;}
.APP-Color_2 { color: rgba(102, 153, 209, 255) !important;}

/* width */
.w-100 { width: 100% !important; }
.w-80 { width: 80% !important; }
.w-95 { width: 95% !important; }
.w-70 { width: 70% !important; }
.w-65 { width: 65% !important; }
.w-50 { width: 50% !important; }
.w-40 { width: 40% !important; }
.w-49 { width: 49% !important; }
.w-35 { width: 35% !important; }
.w-30 { width: 30% !important; }
.w-25 { width: 25% !important; }
.w-400px { width: 400px !important;}

/* height */
.h-100 { height: 100% !important; }
.h-100vh { height: 100vh !important; }
.h-60vh { height: 60vh !important; }
.h-50vh { height: 50vh !important; }
.h-40vh { height: 40vh !important; }
.h-50 { height: 50% !important; }

/* border */
.app-border { border: 2px solid rgb(2, 2, 77) !important; }
.border { border: 1px solid lightgray !important;}
.border-darkblue { border: 1px solid gray; }
.b-r-10 { border-radius: 10px;}
.b-r-7 { border-radius: 7px;}
.b-5 {border-radius: 5px !important;}

/* border radius */

/* Margin */
.m-0 { margin: 0px !important;}
.m-a-10 { margin: 10px !important; }
.m-a-20 { margin: 20px !important; }
.m-a-30 { margin: 30px !important; }
.m-t-10 { margin-top: 10px !important; }
.m-t-20 { margin-top: 20px !important; }
.m-t-30 { margin-top: 30px !important; }
.m-t-40 { margin-top: 40px !important; }
.m-t-50 { margin-top: 50px !important; }
.m-t-60 { margin-top: 60px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-l-10 { margin-left: 10px !important;}
.m-r-10 { margin-right: 10px !important;}
.m-r-15 { margin-right: 15px !important;}
.m-r-20 { margin-right: 20px !important;}
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }
.m-r-30 { margin-right: 30px !important;}
.m-r-40 { margin-right: 40px !important;}
.m-r-50 { margin-right: 50px !important;}

/* Padding */
.p-0 { padding: 0px !important; }
.p-5 { padding: 5px !important; }
.p-8 { padding: 8px; }
.p-16 { padding: 16px !important; }
.p-10 { padding: 10px !important; }
.p-15 { padding: 15px !important; }
.p-l-5 { padding: 5px; }
.p-l-10 { padding-left: 10px; }
.p-r-10 { padding-right: 10px; }
.p-t-10 { padding-top: 10px; }
.p-l-15 { padding-left: 15px !important; }
.p-r-15 { padding-right: 15px; }
.p-b-15 { padding-bottom: 15px; }
.p-l-20 { padding-left: 20px; }
.p-l-25 { padding-left: 25px; }
.p-r-25 { padding-right: 25px; }
.p-l-30 { padding-left: 30px; }
.p-l-40 { padding-left: 40px; }
.p-t-8 { padding-top: 8px !important;}
.p-b-8 { padding-bottom: 8px !important;}
.p-r-10 { padding-right: 10px !important;}
.p-l-10 { padding-left: 10px !important;}

/* Font size */
.fs-12 { font-size: 12px; }
.fs-13 { font-size: 13px; }
.fs-16 { font-size: 16px; }
.fs-20 { font-size: 20px; }
.fs-22 { font-size: 22px !important; }
.fs-25 { font-size: 25px; }
.fs-30 { font-size: 30px;}

/* Font weight */
.fw-500 { font-weight: 500;}
.fw-700 { font-weight: 700;}
.fw-bold { font-weight: bold;}

/* Color */
.gray { color: gray !important; }
.white { color: white !important; }
.dark-blue { color: #2a2666 !important;}
.red { color: red !important;}
.black { color: black !important;}
.light-gray { color: light-gray !important;}
.aliceblue { color: aliceblue !important;}

/* background */
.bg-blue { background-color: #2a2666 !important; }
.bg-white { background-color: white !important; }
.bg-light-gray { background-color: lightgray !important; }
.bg-green { background-color: green !important; }
.bg-aliceblue { background-color: aliceblue !important; }
.bg-red { background-color: red !important; }

/* gap */
.g-3 { gap: 3px !important; }
.g-5 { gap: 5px !important; }

/* overflow */
.overflow-y { overflow-y: auto; }
.overflowx-hidden { overflow-x: hidden }

/* text align */
.align-right { text-align: right !important;}
.text-center { text-align: center !important;}

.table-header { 
  color: black;
  /* font-family:'Times New Roman', Times, serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  font-weight: 700 !important;
  background-color: aliceblue;
  padding: 10 !important;
}

.cursor { cursor: pointer !important; }

.logout {
  margin-bottom: 2px; 
  color: 'white'
}

.d-flex { display: flex; }
.justify-center { justify-content: center;}
.justify-around { justify-content: space-around;}
.justify-between { justify-content: space-between;}
.justify-start { justify-content: start;}
.justify-end { justify-content: end;}
.align-center { align-items: center; }
.flex-direction-row { flex-direction: row;}
.flex-direction-column { flex-direction: column;}
.flex-direction-custom { flex-direction: row;}
.inline { display: inline; }
.flex-wrap { flex-wrap: wrap !important;}

a {
  text-decoration: none;
}

.main-container {
  display: flex;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  color: white;
  min-height: 100vh;
  border-right: 1px solid lightgray;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2666;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 10px 20px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  font-weight: bold;
  background-color: #e9f0f7 !important;;
  opacity: 0.9;
  color: rgb(2, 2, 77) !important;
  border-radius: 0 8px 8px 0;
  margin-right: 5px;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  font-weight: bold;
  color: white !important;
  border-radius: 0 8px 8px 0;
  margin-right: 5px;
  background-color: rgba(102,153,209,255) !important;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  text-decoration: none !important;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 1001;
}

.image-not-found {
  position: relative;
  display: inline-block;
}

.image {
  border-radius: 10px;
  margin-top: 0;
  height: 450px;
  width: 450px;
}

.image-not-found-text {
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
  max-width: 300px;
  font-style: italic;
  font-weight: bold;
  color: #2a2666;
  margin: 0;
}

.card-hover:hover{
  background-color: rgba(191, 219, 243, 0.305);
}

/* ---------TAg Grid Style----------- */

.header-border {
  border-left: 1px solid lightgray;
}

.ag-root-wrapper {
  background-color: #f0f0f0;
  border-radius: 5px;
}

/* ------------------------------------- */
